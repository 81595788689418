import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import desktopImage from "../../assets/lifestyle_1_desktop.png";
import mobileImage from "../../assets/lifestyle_1_mobile.png";
import Container from "../../components/Container/container";
import CTA from "../../components/CTA/cta";
import KickstarterBanner from "../KickstarterBanner/kickstarter-banner";
import "./hero.scss";

import christmasLights from "../../assets/christmas-lights.svg";
import whiteLogo from "../../assets/white-logo.svg";

const imageAltText = {
	fathers_mobile:
		"Dog happily holding on to her Tearrible, a plush dog toy that can be torn apart over and over again.",
	fathers_desktop:
		"Dog happily holding on to her Tearrible, a plush dog toy that can be torn apart over and over again.",
};

const imageClassName = {
	fathers_mobile: "hero__image-full",
	fathers_desktop: "hero__image-full",
};

const heroText = (props) => (
	<div>
		{/* <Logo /> */}
		<h1 className="hero__header">The Toy That Lets Them Be Who They Are</h1>
		{/* <p className="hero__subheader">Designed for dogs.</p> */}
		<CTA
			to="/products"
			styles={{ width: "auto", display: "inline-block" }}
		/>
	</div>
);

const HeroImage = (props) => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

	return (
		<>
			<div>
				<img
					src={desktopImage}
					alt=""
					className="hero__image-full hide-phone"
				/>
				<img
					src={mobileImage}
					alt=""
					className="hero__image-full mobile-only"
				/>
				<div className="hero__mobile">{heroText(props)}</div>
			</div>
		</>
	);
};

export default (props) => (
	<StaticQuery
		query={graphql`
			query heroFullImages {
				allFile(
					filter: {
						relativePath: {
							in: [
								"lifestyle_1_desktop.png"
								"lifestyle_1_mobile.png"
							]
						}
					}
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => {
			const images = data.allFile.edges.reduce((acc, { node }) => {
				console.log(node);
				const { name, childImageSharp } = node;

				if (!childImageSharp || !name) {
					return acc;
				}

				const altText = imageAltText[name];
				const className = imageClassName[name];
				console.log(childImageSharp.fluid);
				const element = (
					<Img
						fluid={{
							src: childImageSharp.fluid.src,
							aspectRatio: childImageSharp.fluid.aspectRatio,
						}}
						className={className}
						alt={altText}
					/>
				);

				acc[name] = element;
				return acc;
			}, {});

			return <HeroImage images={images} {...props} />;
		}}
	</StaticQuery>
);
