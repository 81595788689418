import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import Hero from "../components/Hero/hero";
import Intro from "../components/Intro/intro";
import Details from "../components/Details/details";
import Family from "../components/Family/family";
import Social from "../components/Social/social";
import { ElfsightMain } from "../components/Elfsight/elfsight";
import { BoxLinks } from "../components/BoxLinks/box-links";
import HeroImage from "../components/Hero/hero-image";

class IndexPage extends Component {
	render() {
		const domain =
			process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";
		return (
			<>
				<HeroImage {...this.props} />
				{/* <BoxLinks {...this.props} /> */}
				<Intro {...this.props} />
				<Details {...this.props} />
				{domain === "tearriblesau.myshopify.com" ? (
					<Social {...this.props} />
				) : (
					<ElfsightMain {...this.props} />
				)}
				<Family
					data={this.props.data}
					subHeader={"Fun comes in all sizes"}
				/>
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query mainPageQuery {
				allShopifyCollection(filter: { title: { in: "Tearribles" } }) {
					edges {
						node {
							title
							products {
								id
								title
								descriptionHtml
								productType
								tags
								handle
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <IndexPage data={data} {...props} />}
	</StaticQuery>
);
