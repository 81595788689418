export const ProductTypes = {
	CHEWPLAY: "Chew & Play",
	CHEWPLAYSTICK: "Chew & Play Stick",
	SNIFFPLAY: "Sniff & Play",
	TEARRIBLE: "Tearrible",
	COLLAR: "Collar",
	POOP_BAG: "Poop Bag",
	POOP_BAG_HOLDER: "Poop Bag Holder",
	HARNESS: "Harness",
	GIFT_CARD: "Gift Cards",
	LEASH: "Leash",
	WALKIES: "Walkies",
	COMBO: "Combo",
	PARTS: "Parts",
	CHEWS: "Hard Chews",
	PBJ: "PBJ",
	DUCK: "Duck",
	NUGGS: "Nuggs",
	COLLAGEN: "Collagen Chews",
	BULLY: "Bully Braids",
	BULLY_STICKS: "Bully Sticks",
	CHEEKS: "Beef Cheeks",
	CHIPS: "Beef Chips",
	TREE: "ChristmasTree",
	ULTIMATE: "Ultimate",
	SHIPPING_PROTECTION: "Shipping Protection",
};

export const tearribleStyles = {
	tiny: {
		color: "#F7F651",
		textColor: "black",
		height: '5"',
		subHeader: "Extra Cute",
		index: 1,
	},
	medium: {
		color: "#96da8f",
		// color: "rgb(150 236 243)",
		textColor: "black",
		height: '10"',
		subHeader: "Cute Butt",
		index: 2,
	},
	xl: {
		color: "#8891d3",
		textColor: "black",
		height: '14"',
		subHeader: "Cute Toofs",
		index: 3,
	},
	maximus: {
		color: "#4AE1EF",
		textColor: "black",
		height: '17"',
		subHeader: "Cute & Cuddly",
		index: 4,
	},
	virus: {
		color: "#4792d5",
		textColor: "black",
		height: '10"',
		subHeader: "Cute Spikes",
		index: 5,
	},
	candycane: {
		color: "rgba(208, 30, 40, 0.3)",
		textColor: "white",
		height: '5"',
		subHeader: "Limited Edition Christmas Special",
		index: 1,
		list: "christmas",
	},
	gingerbread: {
		color: "rgba(214, 114, 74, 0.3)",
		textColor: "#96da8f",
		height: '10"',
		subHeader: "Limited Edition Christmas Special",
		index: 2,
		list: "christmas",
	},
	angel: {
		color: "rgba(74, 225, 239, 0.3)",
		textColor: "black",
		height: '14"',
		subHeader: "Limited Edition",
		index: 3,
		list: "christmas",
		imageStyles: {
			transform: "scale(1.5)",
			padding: "0",
		},
	},
	stingray: {
		color: "#2EC6C3",
		textColor: "black",
		height: '14"',
		subHeader: "Fire Hose Material",
		index: 7,
	},
	octopus: {
		color: "#2EC6C3",
		textColor: "black",
		height: '14"',
		heightDescription: "Wide",
		subHeader: "Fire Hose Material",
		index: 7,
	},
	hammerhead: {
		color: "#2EC6C3",
		textColor: "black",
		height: '14"',
		heightDescription: "Long",
		subHeader: "Fire Hose Material",
		index: 7,
	},
	venus: {
		color: "#CAFA6E",
		textColor: "black",
		height: '14"',
		subHeader: "Cute Leafs",
		index: 6,
	},
};
