import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import toggle from '../../assets/x-white.svg';
import Container from '../../components/Container/container';
import CTA from '../../components/CTA/cta';
import './details.scss';

const leftSide = [
    {
        title: `Detachable Limbs`,
        info: `Removable parts designed to simulate dismembering their prey.`,
        number: 1
    }, {
        title: `Pocket System`,
        info: `Designed to ensure the pockets stay within the body.`,
        number: 2
    }, {
        title: `Stationary Squeaker`,
        info: `Centrally located within the main body providing maximum protection.`,
        number: 3
    }
]

const rightSide = [
    {
        title: `Durable Construction`,
        info: `Interior construction provides support of triple stiched velboa lined with mesh.`,
        number: 4
    }, {
        title: `Industrial Strength Velcro`,
        info: `Provides maximum resistance when detaching extremities`,
        number: 5
    }
]

const greenDetail = (
    <StaticQuery
        query={graphql`
            query GreenDetail {
                file(relativePath: { eq: "green-detail-2.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 475) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="details__image" alt="A display of the interactive nature of the Tearrible dog toy, using the Medium variation of the toy." />}
    </StaticQuery>
)

class Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openItems: [1]
        }

        this.renderDetails = this.renderDetails.bind(this);
    }

    toggleViewDetail = (index) => {

        const { openItems } = this.state;

        if (this.state.openItems.includes(index)) {
            const newOpenItems = openItems.filter((x) => x !== index);
            this.setState({ openItems: newOpenItems });
            return;
        }

        const newOpenItems = openItems.slice()
        newOpenItems.push(index);
        this.setState({ openItems: newOpenItems });

    }

    renderDetails = (list) => {
        if (!Array.isArray(list)) return null;
        
        return list.map((item, index) => {
            const displayInfo = this.state.openItems.includes(item.number);
            const toggleClasses = ['details__toggle'];

            if (!displayInfo) {
                toggleClasses.push('details__toggle--inactive');
            }

            return (
                <ul key={item.number}>
                    <li>
                        <div className="details__header" onClick={() => this.toggleViewDetail(item.number)}>
                            <div className="details__number">{item.number}</div>
                            <h4 className="details__title">{item.title}</h4>
                            <img src={toggle} alt={item.info} className={toggleClasses.join(' ')}/>
                        </div>
                        <p className="details__info">{item.info}</p>
                        <AnimateHeight height={displayInfo ? 'auto' : 0} className="details__info--mobile">
                            <p className="details__info details__info--mobile">{item.info}</p>
                        </AnimateHeight>
                    </li>
                </ul>
            )
        })
    }

    render() {
        return (
            <div className="details" style={{ color: '#FFFFFF' }}>
                <Container>
                    <h2 style={{ color: '#FFFFFF' }}>Designed with Dogs in Mind</h2>
                    <div className="details__wrapper">
                        <div className="details__text details__text-left" >
                            {this.renderDetails(leftSide)}
                        </div>
                        {greenDetail}
                        <div className="details__text details__text-right">
                            {this.renderDetails(rightSide)}
                        </div>
                    </div>
                    <div className="details__cta-wrapper">
                        <CTA to="/products" styles={{ width: 'auto' }}/>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Details