import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';


export const comboImage = (
    <StaticQuery
        query={graphql`
            query ComboImage {
                file(relativePath: { eq: "TinyMedium_2.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 475) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="box-links__combo-image" alt="Medium and Tiny Tearrible" />}
    </StaticQuery>
)
