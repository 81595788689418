import React, { Component } from "react";
import AnimateHeight from "react-animate-height";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Container from "../../components/Container/container";
import CTA from "../CTA/cta";
import "./intro.scss";

const imageAltText = {
	all: "The entire line of Tearrible dogs toys, an interactive toy that utilizes velcro to allow a dog to dismember the toy and the owner to put it back together.",
};

class Intro extends Component {
	constructor(props) {
		super(props);

		this.state = {
			displayFullText: false,
		};

		this.toggleShowText = this.toggleShowText.bind(this);
	}

	toggleShowText() {
		this.setState({ displayFullText: !this.state.displayFullText });
	}

	render() {
		const { displayFullText } = this.state;
		const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";

		return (
			<div className="intro">
				<Container>
					<h2>The toy that lets them be who they are</h2>
					<h4>...guilt free</h4>
					<div className="intro__wrapper">
						<p className="mobile-only">
							Dogs have done such a wonderful job adapting to
							living with us that it's easy to forget they have
							different needs and wants from us. They learn our
							rules, our language, our schedules...But something
							magical happens when we allow ourselves to
							experience the world from our dog's perspective.
						</p>
						<p className="mobile-only">
							Here at Tearribles, it is our mission to give every
							dog lover the opportunity to experience the
							wholesome joy of interactive playtime with their
							dogs, and to allow dogs to exercise their natural
							behaviors in a happy, safe environment.
						</p>
						{this.props.images.all3}
						<div
							className="intro__text"
							style={
								displayFullText
									? { paddingBottom: "4rem" }
									: null
							}
						>
							<p className="hide-phone">
								Dogs have done such a wonderful job adapting to
								living with us that it's easy to forget they
								have different needs and wants from us. They
								learn our rules, our language, our
								schedules...But something magical happens when
								we allow ourselves to experience the world from
								our dog's perspective.
							</p>
							<p className="hide-phone">
								Here at Tearribles, it is our mission to give
								every dog lover the opportunity to experience
								the wholesome joy of interactive playtime with
								their dogs, and to allow dogs to exercise their
								natural behaviors in a happy, safe environment.
							</p>
							<h3>What are Tearribles?</h3>
							<p>
								Tearribles are the first line of innovative dog
								toys that cater to dogs' prey instincts. They
								are interactive toys that your dog can "tear
								apart", and you can put right back together in
								seconds.
							</p>
							<h3>Why Tearribles?</h3>
							<p>
								The problem dog owners face is that an average
								plush toy usually meets its demise within
								minutes (or hours at best) of getting into the
								paws of their eager, happy dog. This creates two
								problems:
							</p>
							<div
								className="intro__read-more"
								onClick={this.toggleShowText}
							>
								{displayFullText ? "Show Less" : "Read More"}
							</div>
							<AnimateHeight
								height={displayFullText ? "auto" : 0}
							>
								<p>
									1. At an average price of $5-$15 per toy,
									this play style ends up costing an average
									dog parent hundreds of dollars in dog toys
									every year.
								</p>
								<p>
									2. Dogs end up chewing mauled remains of
									plush toys from boredom which poses a risk
									of intestinal obstruction, or don’t end up
									playing enough because their toys are thrown
									away after one play.
								</p>
								<p>
									What happens next is one of the main reasons
									shelters are filled with dogs.
								</p>
								<p>
									Have you ever wondered how some dogs are
									perfectly happy, calm, and well behaved,
									while others destroy couches, develop
									obsessive behaviors, and become
									unmanageable? The reason is very simple:
									They are bored and frustrated. Providing
									your dog with opportunities to do what they
									enjoy is as important to their health and
									wellbeing as providing them with proper food
									and healthcare.
								</p>
								<p>
									Depriving your dog of play time results in
									the same exact outcome as in humans- they
									become bored, unhappy, anxious, disruptive,
									and destructive.
								</p>
								<p>This is where Tearribles come into play.</p>
								<p>
									The unique pocket design of Tearribles
									allows your dog to experience the
									satisfaction of “killing” their prey without
									actually destroying the toy. Aside from
									allowing them to exercise their natural
									instincts, playing with Tearribles has many
									benefits: it helps build their confidence,
									improve their wellbeing, and provides you
									and your dog with a fun way to build and
									strengthen your bond.
								</p>
							</AnimateHeight>
						</div>
					</div>
					<div className="intro__video-wrapper">
						<iframe
							title="Tearribles - The Toy Your Dog Can Destroy Over and Over Again"
							src="https://www.youtube.com/embed/K8iL-lcmvYg?si=c5Q4GWYAoYtAVbr1"
							allow="encrypted-media;"
							allowFullScreen
							className="intro__video m-auto w-full max-w-4xl aspect-video rounded-xl"
						/>
					</div>
					<div className="intro__cta-wrapper">
						<CTA to="/products" styles={{ width: "auto" }} />
					</div>
					{/* <div className="intro__wrapper">
                        <div className="intro__text">
                            <p>What happens next is one of the main reasons shelters are filled with dogs.  Have you ever wondered how some dogs are perfectly happy, calm, and well behaved, while others destroy couches, develop obsessive behaviors, and become unmanageable? The reason is very simple: They are bored and frustrated. Providing your dog with opportunities to do what they enjoy is as important to their health and wellbeing as providing them with proper food and healthcare.</p>
                            <p>Depriving your dog of play time results in the same exact outcome as in humans- they become bored, unhappy, anxious, disruptive, and destructive.</p>
                            <p>This is where Tearribles come into play.</p>
                            <p>The unique pocket design of Tearribles allows your dog to experience the satisfaction of “killing” their prey without actually destroying the toy.  Aside from allowing them to exercise their natural instincts, playing with Tearribles has many benefits: it helps build their confidence, improve their wellbeing, and provides you and your dog with a fun way to build and strengthen your bond.</p>
                        </div>
                        <img className="intro__image" src={all} alt="All Tearribles" />
                    </div> */}
				</Container>
			</div>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query introImages {
				allFile(
					filter: {
						relativePath: { in: ["christmas-dog.png", "all3.jpg"] }
					}
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => {
			const images = data.allFile.edges.reduce((acc, { node }) => {
				const { name, childImageSharp } = node;

				if (!childImageSharp || !name) {
					return acc;
				}

				const altText = imageAltText[name];
				const element = (
					<Img
						fluid={childImageSharp.fluid}
						className="intro__image"
						alt={altText}
					/>
				);

				acc[name] = element;
				return acc;
			}, {});

			return <Intro images={images} {...props} />;
		}}
	</StaticQuery>
);
