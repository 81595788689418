import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';


export const poopbagsImage = (
    <StaticQuery
        query={graphql`
            query PoopbagsImage {
                file(relativePath: { eq: "poop-bags.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 475) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="box-links__bags-image" alt="Poop bags" />}
    </StaticQuery>
)
