import React from 'react'
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './kickstarter-banner.scss';

const kickstarterLogo = (
    <StaticQuery
        query={graphql`
            query KickstarterLogo {
                file(relativePath: { eq: "kickstarter-logo.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 225) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="kickstarter__logo" alt="The dog toy that reached 751% on Kickstarter." />}
    </StaticQuery>
)

const KickstarterBanner = (props) => (
    <div className="kickstarter">
        <div className="kickstarter__content"><span className="kickstarter__span">751%</span> FUNDED ON</div>
        {kickstarterLogo}
    </div>
)

export default KickstarterBanner;