import React from "react";
import Img from "gatsby-image";
import { Link } from "@reach/router";

import Container from "../../components/Container/container";
import { Stars } from "../Stars/stars";
import { tearribleStyles } from "../../types";
import "./family.scss";

const defaultSizes = ["tiny", "medium", "xl"];

const Family = ({
	data,
	title,
	includedSizes = defaultSizes,
	subHeader = null,
}) => {
	// Verify there is only one collection being utilized.
	let collection = data?.allShopifyCollection?.edges.find(({ node }) => {
		return node.title === "Tearribles";
	});

	let products = collection.node.products
		.map((node) => {
			const { title, handle } = node;
			const size = handle.split("-")[1];

			node.size = size;
			return node;
		})
		.filter((node) => {
			const { handle } = node;
			return !handle.includes("special");
		})
		.filter((node) => includedSizes.includes(node.size))
		.sort((a, b) => {
			const aSize = tearribleStyles[a.size].index;
			const bSize = tearribleStyles[b.size].index;

			if (aSize > bSize) return 1;
			if (aSize < bSize) return -1;
			return 0;
		});

	const renderProduct = (node) => {
		const images = node?.featuredImage?.localFile?.childImageSharp?.fluid
			? node?.featuredImage?.localFile?.childImageSharp?.fluid
			: node.images.map((node) => node.localFile.childImageSharp.fluid);
		const { id, title, productType, handle } = node;
		const size = handle.split("-")?.[1];

		if (productType !== "Tearrible") return null;

		const details = tearribleStyles[size];

		return (
			<div className="family__member" key={id}>
				<Link to={`product/${handle}`}>
					<div className="family__image-wrapper">
						<Img
							fluid={Array.isArray(images) ? images[0] : images}
							alt={title}
							className="family__image"
						/>
					</div>
					<div
						className="family__text family__text--medium"
						style={{
							background: details?.color,
							color: details?.textColor,
						}}
					>
						<div className="family__title">{size} Tearrible</div>
						<div className="family__image-stars">
							<Stars style={{ color: "#4d4d5a" }} />
						</div>
						<div
							className="family__text-divider"
							style={{ background: details?.textColor }}
						/>
						<div className="family__text-info">
							{details?.height} Tall
							<br />
						</div>
						<div className="family__text-info">
							{details?.subHeader}
						</div>
					</div>
				</Link>
			</div>
		);
	};

	return (
		<div className="family">
			<Container>
				<h2>{title ? title : "Meet the Tearribles Family"}</h2>
				{subHeader ? <h4>{subHeader}</h4> : null}
				<div className="family__wrapper">
					{products.map(renderProduct)}
				</div>
			</Container>
		</div>
	);
};

export default React.memo(Family);
