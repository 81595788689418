import React, { useState } from 'react'
import { navigate, StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image';

import './box-links.scss';
import Container from '../Container/container';
import { comboImage } from './images/combo-image'
import { harnessImage } from "./images/harness-image";
import { poopbagsImage } from "./images/bags-image";

const tearriblesImage = (
    <StaticQuery
        query={graphql`
            query TearriblesImage {
                file(relativePath: { eq: "dog-with-tiny-tearrible.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 475) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        `}
    >
        {data => <Img fluid={data.file.childImageSharp.fluid} className="box-links__tearribles-image" alt="Dog with tearrible" />}
    </StaticQuery>
)

export const BoxLinks = () => {

    return (
        <Container>
            <div className='box-links'>
                <Link to="/products" className='box-links__box box-links__tearribles'>
                    {tearriblesImage}
                    <label>Dog Toys</label>
                </Link>
                {/* <Link to="/products/combos" className='box-links__box box-links__combos'>
                    {comboImage}
                    <label>Combos</label>
                </Link> */}
                <Link to="/products/gear" className='box-links__box box-links__gear'>
                    {harnessImage}
                    <label>Gear</label>
                </Link>
                <Link to="/product/biodegradable-poop-bags" className='box-links__box box-links__bags'>
                    {poopbagsImage}
                    <label>Poop Bags</label>
                </Link>
            </div>
        </Container>
    )

}