import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons";
import "./stars.scss";

export const Stars = ({ style = {}, rating = 5, reviews = [] }) => {
	if (!Array.isArray(reviews) || reviews.length === 0) {
		return null;
	}

	return (
		<div
			className="star-wrapper"
			style={{ maxHeight: "2rem", overflow: "hidden", ...style }}
		>
			<FontAwesomeIcon icon={faStar} />
			<FontAwesomeIcon icon={rating >= 2 ? faStar : faStarOutline} />
			<FontAwesomeIcon icon={rating >= 3 ? faStar : faStarOutline} />
			<FontAwesomeIcon icon={rating >= 4 ? faStar : faStarOutline} />
			<FontAwesomeIcon icon={rating >= 5 ? faStar : faStarOutline} />
		</div>
	);
};

export const StarsInput = ({ setValue, currentValue, style }) => {
	return (
		<div
			className="star-wrapper"
			style={{
				...style,
				fontSize: "24px",
				cursor: "pointer",
				gap: "4px",
				display: "flex",
				flexDirection: "row",
			}}
		>
			<FontAwesomeIcon
				icon={faStar}
				onClick={() => setValue("rating", 1)}
			/>
			<FontAwesomeIcon
				icon={currentValue >= 2 ? faStar : faStarOutline}
				onClick={() => setValue("rating", 2)}
			/>
			<FontAwesomeIcon
				icon={currentValue >= 3 ? faStar : faStarOutline}
				onClick={() => setValue("rating", 3)}
			/>
			<FontAwesomeIcon
				icon={currentValue >= 4 ? faStar : faStarOutline}
				onClick={() => setValue("rating", 4)}
			/>
			<FontAwesomeIcon
				icon={currentValue >= 5 ? faStar : faStarOutline}
				onClick={() => setValue("rating", 5)}
			/>
		</div>
	);
};

export default Stars;
